import { Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { GuardedComponent } from './features/guarded/pages/guarded.component';
import { RoleGuard } from './core/auth/role.guard';

export const routes: Routes = [
  {
    path: '',
    canActivateChild: [MsalGuard],
    // All routes that should be protected by MSAL guard should be children of this route
    children: [
      {
        path: 'guarded',
        component: GuardedComponent,
        data: {
          roles: ['CareHub.Admin'],
        },
        canActivate: [RoleGuard],
      },
    ],
  },
  /** Route that catches nonexisting routes and redirects them to home */
  {
    path: '**',
    redirectTo: '',
  },
];
