<p-toast />
<carehub-header></carehub-header>
<carehub-sidebar></carehub-sidebar>
<div class="container">
    <!-- demo for login -->
    @if (!isLoggedIn()) {
    <button mat-menu-item (click)="login()">Login using Redirect</button>
    }
    @if (isLoggedIn()) {
    <button mat-menu-item (click)="logout()">Logout using Redirect</button>
    }
    <router-outlet />
</div>