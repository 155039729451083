import { Injectable, signal, WritableSignal } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class MenuService {
  showMenu: WritableSignal<boolean> = signal<boolean>(false);

  /**
   * Toggle the sidebar menu
   */
  toggleMenu(): void {
    this.showMenu.set(!this.showMenu());
  }

  /**
   * Get the current state of the sidebar menu
   * @returns boolean
   */
  getShowMenuState(): boolean {
    return this.showMenu();
  }
}
