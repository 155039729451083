import { Component } from '@angular/core';
import { SidebarModule } from 'primeng/sidebar';

import { MenuService } from '../../services/menu.service';

@Component({
  selector: 'carehub-sidebar',
  standalone: true,
  imports: [SidebarModule],
  templateUrl: './sidebar.component.html',
  styleUrl: './sidebar.component.scss',
})
export class SidebarComponent {
  opened: boolean = false;

  constructor(private _menuService: MenuService) { }

  /**
   * Get the current state of the sidebar menu
   */
  get toggleState(): boolean {
    return this._menuService.getShowMenuState();
  }

  /**
   * Toggle the sidebar menu
   */
  toggleMenu(): void {
    this._menuService.toggleMenu();
  }
}
