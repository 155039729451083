import { Injectable } from '@angular/core';
import * as signalR from '@microsoft/signalr';

import { environment } from '../../../environments/environment';
import { Notification } from '../models/notification';

@Injectable({
    providedIn: 'root'
})
export class SignalRService {
    private notificationHubPath = '/NotificationHub';
    private notificationHubConnection: signalR.HubConnection;

    constructor() {
        this.notificationHubConnection = new signalR.HubConnectionBuilder()
            .withUrl(`${environment.apiUrl}${this.notificationHubPath}`, {
                skipNegotiation: true,
                transport: signalR.HttpTransportType.WebSockets
            })
            .build();

        this.notificationHubConnection
            .start();
    }

    public addNotificationListener(callback: (notification: Notification) => void): void {
        this.notificationHubConnection.on('ReceiveNotification', (notification: Notification) => {
            callback(notification);
        });
    }
}
