import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { firstValueFrom } from 'rxjs';
import { ServiceRequest } from './service-request';
import { AuthService } from '../../core/auth/auth.service';

@Injectable({
  providedIn: 'root',
})
export class ServiceRequestService {
  route: string = environment.apiUrl + '/api/Tickets';

  constructor(
    private _http: HttpClient,
    private _authService: AuthService
  ) {}

  /**
   * Get all servicerequest items
   *
   * @returns Promise<ServiceRequest[]> with the response data
   */
  get(): Promise<ServiceRequest[]> {
    return firstValueFrom(this._http.get<ServiceRequest[]>(this.route));
  }

  /**
   * Get a servicerequest item by id
   *
   * @param id number
   * @returns Promise<ServiceRequest> with the response data
   */
  show(id: number): Promise<ServiceRequest> {
    return firstValueFrom(
      this._http.get<ServiceRequest>(this.route + '?id=' + id)
    );
  }

  /**
   * Create a servicerequest item
   *
   * @param servicerequest ServiceRequest
   * @returns Promise<ServiceRequest> with the response data
   */
  create(servicerequest: ServiceRequest): Promise<ServiceRequest> {
    const formData: FormData = new FormData();

    formData.append('Title', servicerequest.title);
    formData.append('Description', servicerequest.description);
    formData.append('Requester', this._authService.getUserMail());
    formData.append('Critical', servicerequest.critical ? 'true' : 'false');
    formData.append('Compliance', servicerequest.compliance ? 'true' : 'false');
    formData.append(
      'Confidential',
      servicerequest.confidential ? 'true' : 'false'
    );
    formData.append('DueDate', servicerequest.dueDate ?? '');

    servicerequest.files?.forEach((file: File) => {
      formData.append('Files', file);
    });

    return firstValueFrom(
      this._http.post<ServiceRequest>(this.route, formData)
    );
  }

  /**
   * Update a servicerequest item
   *
   * @param servicerequest ServiceRequest
   * @returns Promise<ServiceRequest> with the response data
   */
  update(servicerequest: ServiceRequest): Promise<ServiceRequest> {
    return firstValueFrom(
      this._http.put<ServiceRequest>(
        this.route + '/' + servicerequest.id,
        servicerequest
      )
    );
  }

  /**
   * Will start or join a teams chat regarding the servicerequest
   *
   * @param servicerequest ServiceRequest
   */
  joinTeamsChat(servicerequest: ServiceRequest): Promise<object> {
    if (servicerequest.chatId === '') {
      // Start a new chat
      return firstValueFrom(
        this._http.post(this.route + '/' + servicerequest.id + '/chat', {
          ticketId: servicerequest.id,
          requester: servicerequest.requester,
          assigned: servicerequest.assignedTo,
        })
      );
    } else {
      // Join existing chat
      return firstValueFrom(
        this._http.put(this.route + '/' + servicerequest.id + '/chat', {
          ticketId: servicerequest.id,
          requester: this._authService.getUserMail(),
          chatId: servicerequest.chatId,
        })
      );
    }
  }

  /**
   * Will calculate the priority of the servicerequest
   *
   * @param serviceRequest ServiceRequest
   * @returns number with the priority
   */
  calculatePriority(serviceRequest: ServiceRequest): number {
    // If the service request is critical, set priority to 1
    if (serviceRequest.critical) {
      return 1;
    }

    let priority: number = 0;
    let divider: number = 0;

    // ServiceRequest due date priority
    if (serviceRequest.dueDate != null) {
      divider += 1;

      if (this.isDueDateWithin(7, serviceRequest.dueDate)) {
        priority += 1;
      }

      if (this.isDueDateWithin(14, serviceRequest.dueDate)) {
        priority += 2;
      }

      if (this.isDueDateWithin(60, serviceRequest.dueDate)) {
        priority += 3;
      }
    }

    // Handle severity
    if (serviceRequest.severity != null) {
      divider += 1;
      priority += serviceRequest.severity;
    }

    // Handle urgency
    if (serviceRequest.urgency != null) {
      divider += 1;
      priority += serviceRequest.urgency;
    }

    // Handle compliance
    if (serviceRequest.compliance) {
      divider += 1;
      priority += 1;
    }

    // Check if priority is NaN, if so return 4 (lowest priority)
    return isNaN(priority / divider) ? 4 : priority / divider;
  }

  /**
   * Will check if the due date is within the given days
   *
   * @param days number
   * @param dueDate string
   * @returns boolean
   */
  isDueDateWithin(days: number, dueDate: string): boolean {
    const today: Date = new Date();
    const servicerequestDueDate: Date = new Date(dueDate);
    return (
      servicerequestDueDate >
      new Date(new Date().setDate(today.getDate() + days))
    );
  }
}
