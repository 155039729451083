import { Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { GuardedComponent } from './features/guarded/pages/guarded.component';

import { RoleGuard } from './core/auth/role.guard';

export const routes: Routes = [
    {
        path: 'guarded',
        component: GuardedComponent,
        data: {
            roles: ['CareHub.Admin']
        },
        canActivate: [
            MsalGuard, RoleGuard
        ]
    }
];
