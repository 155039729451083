import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { ToastService } from '../services/toast.service';

@Injectable()
export class ToastsInterceptor implements HttpInterceptor {
  constructor(private _toastService: ToastService) { }

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    if (request.method === 'GET') {
      return next.handle(request);
    }

    return next.handle(request).pipe(
      tap({
        next: (response: HttpEvent<unknown>) => {
          if (response instanceof HttpResponse) {
            this._toastService.showToastBasedOnResponse(response);
          }
        },
        error: (error: Error) => {
          if (error instanceof HttpErrorResponse) {
            this._toastService.showToastBasedOnResponse(error);
          }
        },
      })
    );
  }
}
