import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AvatarModule } from 'primeng/avatar';
import { MenubarModule } from 'primeng/menubar';
import { ProgressBarModule } from 'primeng/progressbar';

import { LoaderService } from '../../services/loader.service';
import { MenuService } from '../../services/menu.service';
import { Loaderbar } from '../../models/loaderbar';

@Component({
  selector: 'carehub-header',
  standalone: true,
  imports: [
    FormsModule,
    CommonModule,
    MenubarModule,
    ProgressBarModule,
    AvatarModule,
  ],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss',
})
export class HeaderComponent {
  constructor(
    private _loaderService: LoaderService,
    private _menuService: MenuService
  ) { }

  /**
   * Toggle the sidebar menu
   */
  toggleMenu(): void {
    this._menuService.toggleMenu();
  }

  /**
   * Returns the current state of the loader.
   * @returns Loaderbar
   */
  get loaderbarState(): Loaderbar {
    return this._loaderService.getLoaderState();
  }

  /**
   * Method to show the correct color loading bar based on request type.
   *
   * @returns string
   */
  determineLoaderColor(): string {
    // Below methods are getting the colors defined in the 2care4 theme variables.scss file
    switch (this.loaderbarState.requestMethod) {
      case 'GET':
        return 'secondary-color';

      case 'POST':
      case 'PUT':
        return 'primary-color';

      case 'DELETE':
        return 'danger-color';

      default:
        throw new Error(
          'Request method not yet implemented: ' +
          this.loaderbarState.requestMethod
        );
    }
  }
}
