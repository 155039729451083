<div class="header">
  <p-menubar>
    <ng-template pTemplate="start">
      <div class="header__start">
        <span class="pi pi-bars header__icon" tabindex="0" (click)="toggleMenu()"></span>
        <img src="2care4logo.svg" alt="2Care4 logo" height="35px" />
      </div>
    </ng-template>
    <ng-template pTemplate="end">
      <div class="header__end">
        <p-menu #menu [model]="notificationMenuItems()" [popup]="true">
          <ng-template pTemplate="item" let-item>
            <ng-container>
              <a [href]="item.url" class="p-menuitem-link" [ngClass]="{'menu-item__link--unread': !isRead(item)}"
                (click)="openNotification(item.id)" target="_blank" rel="noopener noreferrer">
                <span class="ml-2">{{ item.label }}</span>
              </a>
            </ng-container>
          </ng-template>
        </p-menu>
        <i class="pi pi-bell badge" [ngClass]="unreadNotificationsCount() ? '' : 'badge--empty'" pBadge
          [value]="unreadNotificationsCount()" (click)="menu.toggle($event)" tabindex="0"></i>
      </div>
    </ng-template>
  </p-menubar>

  @if (loaderbarState.loading) {
  <p-progressBar mode="indeterminate" [styleClass]="'header__progress-bar ' + determineLoaderColor()" />
  } @else {
  <!-- This div is to make sure that there is always space for the loaderbar, so if nothing is loading, avoiding content "jumping" -->
  <div class="header__empty-bar"></div>
  }
</div>