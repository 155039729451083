import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MessageService } from 'primeng/api';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  constructor(private _messageService: MessageService) {}

  /**
   * Show toast based on http response from a request
   * @param response HttpResponse | HttpErrorResponse the response from the request, to determine the toast
   * @returns
   */
  showToastBasedOnResponse(
    response: HttpResponse<unknown> | HttpErrorResponse
  ): void {
    switch (response.status) {
      // SUCCESS
      case 200: // OK
      case 201: // Created
        this._messageService.add({ severity: 'success', detail: 'Success' });
        break;

      // CLIENT ERRORS
      case 400: // Bad Request
      case 401: // Unauthorized
      case 403: // Forbidden
      case 404: // Not Found

      // SERVER ERRORS

      // The no fallthrough rule disabled to avoid duplicate code and readability
      // eslint-disable-next-line no-fallthrough
      case 500: // Internal Server Error
      case 502: // Bad Gateway
      case 503: // Service Unavailable
      case 504: // Gateway Timeout
        // TODO: Add handling for errors, to show the error message in the toast, perhaps open a modal with the error message?
        this._messageService.add({ severity: 'error', detail: 'Error' });
        break;

      default:
        throw new Error(
          'Response status not yet implemented: ' + response.status
        );
    }
  }
}
