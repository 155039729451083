import { Inject, Injectable, signal, WritableSignal } from '@angular/core';
import { Router } from '@angular/router';
import { MSAL_GUARD_CONFIG, MsalBroadcastService, MsalGuardConfiguration, MsalService } from '@azure/msal-angular';
import { AccountInfo, EventMessage, EventType, InteractionStatus, RedirectRequest } from '@azure/msal-browser';
import { filter, Subject, takeUntil } from 'rxjs';

import { UserAccount } from '../models/user-account';

@Injectable({ providedIn: 'root' })
export class AuthService {
    private readonly destroying$ = new Subject<void>();
    userAccount: WritableSignal<UserAccount> = signal({} as UserAccount);
    isLoggedIn: WritableSignal<boolean> = signal(false);

    constructor(
        @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
        private msalService: MsalService,
        private msalBroadcastService: MsalBroadcastService,
        private router: Router
    ) { }

    /**
     * Initializes the auth service
     */
    initialize(): void {
        this.msalService.handleRedirectObservable().subscribe();
        this.setLoginDisplay();

        this.msalService.instance.enableAccountStorageEvents();
        this.msalBroadcastService.msalSubject$
            .pipe(
                filter(
                    (msg: EventMessage) =>
                        msg.eventType === EventType.ACCOUNT_ADDED ||
                        msg.eventType === EventType.ACCOUNT_REMOVED
                )
            )
            .subscribe(() => {
                if (this.msalService.instance.getAllAccounts().length === 0) {
                    this.router.navigate(['/']);
                }
                else {
                    this.setLoginDisplay();
                }
            });

        this.msalBroadcastService.inProgress$
            .pipe(
                filter(
                    (status: InteractionStatus) => status === InteractionStatus.None
                ),
                takeUntil(this.destroying$)
            )
            .subscribe(() => {
                this.setLoginDisplay();
                this.checkAndSetActiveAccount();
            });
    }

    /**
     * Check and sets an active account
     * If no active account set but there are accounts signed in, sets first account to active account
     */
    checkAndSetActiveAccount(): void {
        const activeAccount: AccountInfo | null =
            this.msalService.instance.getActiveAccount();

        if (
            !activeAccount &&
            this.msalService.instance.getAllAccounts().length > 0
        ) {
            const accounts: AccountInfo[] =
                this.msalService.instance.getAllAccounts();
            this.msalService.instance.setActiveAccount(accounts[0]);
        }
    }

    /**
     * Sets isLoggedIn flag
     */
    setLoginDisplay(): void {
        const accounts: AccountInfo[] =
            this.msalService.instance.getAllAccounts();
        this.isLoggedIn.set(accounts.length > 0);

        if (this.isLoggedIn()) {
            const activeAccount: AccountInfo = accounts[0];
            this.userAccount.set({
                name: activeAccount.username,
                fullName: activeAccount.name
            });
        }
    }

    /**
     * Logs in the user by redirecting to the authentication provider
     */
    login(): void {
        if (this.msalGuardConfig.authRequest) {
            this.msalService.loginRedirect({
                ...this.msalGuardConfig.authRequest,
            } as RedirectRequest);
        }
        else {
            this.msalService.loginRedirect();
        }
    }

    /**
     * Logs the user out
     */
    logout(): void {
        this.msalService.logoutRedirect();
    }

    /**
     * Cleans up resources and completes the destroying$ subject.
     * This method should be called to properly dispose of the service.
     */
    dispose(): void {
        this.destroying$.next();
        this.destroying$.complete();
    }
}
