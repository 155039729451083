import { Environment } from '../app/core/models/environment';

export const environment: Environment = {
    environmentName: 'test',
    production: false,
    azureAd: {
        clientId: '32a97aa5-838e-488a-9e40-f6488e00f514',
        tenantId: '8c1b69f3-607d-49aa-91c5-47073e72c768',
        scopeUrls: [
            'api://92955b00-b431-478b-8b28-164ed5832ccb/CareHub.Read.All',
            'api://92955b00-b431-478b-8b28-164ed5832ccb/CareHub.Write.All',
        ]
    },
    apiUrl: 'https://app-carehub-test-001.azurewebsites.net'
};
