import { Injectable, signal, WritableSignal } from '@angular/core';

import { Loaderbar } from '../models/loaderbar';

@Injectable({
  providedIn: 'root',
})
export class LoaderService {
  loadingState: WritableSignal<Loaderbar> = signal<Loaderbar>({
    loading: false,
  });

  /**
   * Method set the loading state to true and the request method.
   *
   * @param method should be the request type; GET,POST etc.
   */
  show(method: string): void {
    this.loadingState.set({ loading: true, requestMethod: method });
  }

  /**
   * Method to update the loadingState var to hide the loader.
   */
  hide(): void {
    this.loadingState.set({ loading: false });
  }

  /**
   * Returns the current state of the loader.
   *
   * @returns Loaderbar
   */
  getLoaderState(): Loaderbar {
    return this.loadingState();
  }
}
