import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate } from "@angular/router";
import { MsalService } from "@azure/msal-angular";

@Injectable({
    providedIn: 'root'
})
export class RoleGuard implements CanActivate {
    constructor(private msalService: MsalService) { }

    canActivate(next: ActivatedRouteSnapshot): boolean {
        const account = this.msalService.instance.getActiveAccount();
        if (!account || !account.idTokenClaims || !account.idTokenClaims.roles) {
            return false;
        }

        const userRoles = account.idTokenClaims.roles;
        const allowedRoles = next.data["roles"];

        if (!Array.isArray(userRoles) || !Array.isArray(allowedRoles)) {
            return false;
        }

        const matchingRoles = userRoles.filter(role => allowedRoles.includes(role));
        return matchingRoles.length > 0;
    }
}