<div class="header">
  <p-menubar>
    <ng-template pTemplate="start">
      <div class="header__start">
        <span
          class="pi pi-bars header__icon"
          tabindex="0"
          (click)="toggleMenu()"></span>
        <img src="2care4logo.svg" alt="2Care4 logo" height="35px" />
      </div>
    </ng-template>
  </p-menubar>

  @if (loaderbarState.loading) {
    <p-progressBar
      mode="indeterminate"
      [styleClass]="'header__progress-bar ' + determineLoaderColor()" />
  } @else {
    <!-- This div is to make sure that there is always space for the loaderbar, so if nothing is loading, avoiding content "jumping" -->
    <div class="header__empty-bar"></div>
  }
</div>
