import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom, Observable } from 'rxjs';

import { environment } from '../../../environments/environment';
import { Notification } from '../models/notification';

@Injectable({ providedIn: 'root' })
export class NotificationService {
    private notificationsEndpoint = '/api/notifications';

    constructor(private http: HttpClient) { }

    async markNotificationAsRead(id: number): Promise<void> {
        const put$: Observable<void> =
            this.http.put<void>(`${environment.apiUrl}${this.notificationsEndpoint}/${id}/read`, null);
        return await firstValueFrom(put$);
    }

    async getNotificationsByAssignedTo(assignedTo: string): Promise<Notification[]> {
        const notifications$: Observable<Notification[]> =
            this.http.get<Notification[]>(`${environment.apiUrl}${this.notificationsEndpoint}?assignedTo=${assignedTo}`);
        return await firstValueFrom(notifications$);
    }
}
