import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { AccountInfo } from '@azure/msal-browser';
import { MessageService } from 'primeng/api';

@Injectable({
  providedIn: 'root',
})
export class RoleGuard implements CanActivate {
  constructor(
    private _msalService: MsalService,
    private _messageService: MessageService
  ) { }

  canActivate(next: ActivatedRouteSnapshot): boolean {
    const account: AccountInfo | null =
      this._msalService.instance.getActiveAccount();
    if (!account || !account.idTokenClaims || !account.idTokenClaims.roles) {
      return false;
    }

    const userRoles: string[] = account.idTokenClaims.roles;
    const allowedRoles: string[] = next.data['roles'];

    if (!Array.isArray(userRoles) || !Array.isArray(allowedRoles)) {
      return false;
    }

    const matchingRoles: string[] = userRoles.filter(role =>
      allowedRoles.includes(role)
    );

    if (matchingRoles.length > 0) {
      return true;
    }
    else {
      this._messageService.add({
        severity: 'error',
        detail: 'You do not have the required roles to access the page',
      });
      return false;
    }
  }
}
